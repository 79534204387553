<template>
  <div class="body">
    <!-- banner区域 Start -->
    <div class="banner">
      <m-top-bar></m-top-bar>
    </div>
    <!-- banner区域 End -->

    <!-- switchBar区域 Start -->
    <div class="switchBar">
      <div class="switchBox">AGV移动机器人覆盖漫游传输</div>
    </div>
    <div class="line"></div>
    <!-- switchBar区域 End -->

    <!-- container区域 Start -->
    <div class="container">
      <div class="containerBox">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;工厂自动化的普及以及智能工厂的建设，AGV的落地使用已经成为了一些智能工厂的标配生产工具，
        LINKOM无线基站搭配多功能控制器及智能维护APP以配合多家智能AGV机器人公司诸多项目落地，
        以其无线切换的漫游及优化管理功能保障了实施机器人作业的稳定性及便利性。
        LINKOM的无线基站及控制器已广泛应用于AGV机器人企业、仓储覆盖等多种应用场景。
        <img src="../../../../assets/mobile/img/solution/robotPic.png" alt="" class="pic">
      </div>
    </div>
    <!-- container区域 End -->

    <!-- baseBar区域 Start -->
    <m-base-bar></m-base-bar>
    <!-- baseBar区域 End -->
  </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
  name: 'Support',
  components: {
    mTopBar,
    mBaseBar,
  }
}
</script>

<style scoped>
.body {
  background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
  width: 100%;
  min-height: 2050px;
  background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
  background-size: 335%;
}

/* switchBar区域 */
.switchBar {
  padding-top: 50px;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: #ffffff;
}

.switchBar .switchBox {
  margin: 0 auto;
  text-align: center;
  width: 1100px;
  height: 200px;
  font-size: 72px;
  line-height: 200px;
  color: #ffffff;
  border-radius: 30px;
  background-color: #c3a35d;
}

.line {
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
  padding: 50px;
  width: 100%;
  min-height: 500px;
}

.container .containerBox {
  padding: 100px;
  width: 100%;
  min-height: 300px;
  font-size: 70px;
  text-align: justify;
  background-color: #ffffff;
}

.container .containerBox .pic {
  margin-top: 150px;
  width: 1600px;
}
</style>