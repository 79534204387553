<template>
  <div>
    <div class="topBar" ref="topBar">
      <div class="fl" @click="backToHome">
        <img src="../assets/mobile/common/pic/logoWW.png" class="logo">
      </div>
      <div class="fr" @click="showSilderBar">
        <img src="../assets/mobile/common/pic/silder.png" class="sildeBtn">
      </div>
      <div class="silderBar" v-show="show" ref="silderBar">
        <!-- 产品中心Start -->
        <div class="li" @click="productOpen">
          <span>产品中心</span>
          <span class="icon">
            <img src="../assets/mobile/common/pic/pre.png" class="pre">
          </span>
        </div>
        <div ref="productOpen" class="proLi">
          <div class="li littleLi " @click="toProductIndustry">
            <span>工业覆盖传输产品系列</span>
          </div>
          <div class="li littleLi" @click="toProductCar">
            <span>无线车载传输产品系列</span>
          </div>
          <div class="li littleLi" @click="toProductAbout">
            <span>相关无线覆盖传输产品规格选型</span>
          </div>
          <div class="li littleLi" @click="toProductPlatform">
            <span>无线系统管理平台</span>
          </div>
          <div class="li littleLi" @click="toProductMatch">
            <span>无线选配附件系列</span>
          </div>
          <div class="li littleLi" @click="toProductCabinet">
            <span>智能无线保管柜系列</span>
          </div>
        </div>
        <!-- 产品中心End -->

        <!-- 解决方案Start -->
        <div class="li" @click="solutionOpen">
          <span>解决方案</span>
          <span class="icon">
            <img src="../assets/mobile/common/pic/pre.png" class="pre">
          </span>
        </div>
        <div ref="solutionOpen" class="solLi">
          <div class="li littleLi" @click="toSolutionVideo">
            <span>视频传输应用</span>
          </div>
          <div class="li littleLi" @click="toSolutionData">
            <span>数据采集应用</span>
          </div>
          <div class="li littleLi" @click="toSolutionRobot">
            <span>移动机器无线覆盖传输</span>
          </div>
          <div class="li littleLi" @click="toSolutionCabinet">
            <span>智能无线保管柜</span>
          </div>
        </div>
        <!-- 解决方案End -->

        <!-- 支持&服务Start -->
        <div class="li" @click="supportOpen">
          <span>支持&服务</span>
          <span class="icon">
            <img src="../assets/mobile/common/pic/pre.png" class="pre">
          </span>
        </div>
        <div ref="supportOpen" class="supLi">
          <div class="li littleLi" @click="toSupport">
            <span>服务&维修</span>
          </div>
          <div class="li littleLi" @click="toAssets">
            <span>资源中心</span>
          </div>
        </div>
        <!-- 支持&服务End -->

        <!-- 关于宁讯Start -->
        <div class="li" @click="toAbout">
          <span>关于宁讯</span>
          <span class="icon">
            <img src="../assets/mobile/common/pic/pre.png" class="pre">
          </span>
        </div>
        <!-- 关于宁讯End -->
        <div class="text">
          专注无线智能产品及方案
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'topBar',
  data() {
    return {
      show: false,
    }
  },
  methods: {
    // 禁止页面滑动
    stop() {
      var mo = function (e) { e.preventDefault(); };
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", mo, false);
    },
    // 允许页面滑动
    move() {
      var mo = function (e) { e.preventDefault(); };
      document.body.style.overflow = '';
      document.addEventListener("touchmove", mo, false);
    },
    // 返回首页
    backToHome() {
      this.$router.replace('/mHome')
      this.move()
    },
    // 产品中心栏打开
    productOpen() {
      console.log('1')
      this.$refs.productOpen.style.display = "block"
      this.$refs.solutionOpen.style.display = "none"
      this.$refs.supportOpen.style.display = "none"
    },
    // 解决方案栏打开
    solutionOpen() {
      console.log('2')
      this.$refs.productOpen.style.display = "none"
      this.$refs.solutionOpen.style.display = "block"
      this.$refs.supportOpen.style.display = "none"
    },
    // 支持&服务栏打开
    supportOpen() {
      console.log('3')
      this.$refs.productOpen.style.display = "none"
      this.$refs.solutionOpen.style.display = "none"
      this.$refs.supportOpen.style.display = "block"
    },
    // 页面跳转
    // 跳转至工业覆盖传输产品系列
    toProductIndustry() {
      this.$router.push('mProductIndustry')
      this.move()
    },
    // 跳转至无线车载传输产品系列
    toProductCar() {
      this.$router.push('mProductCar')
      this.move()
    },
    // 跳转至相关无线覆盖传输产品规格选型
    toProductAbout() {
      this.$router.push('mProductAbout')
      this.move()
    },
    // 跳转至无线系统管理平台
    toProductPlatform() {
      this.$router.push('mProductPlatform')
      this.move()
    },
    // 跳转至无线选配附件系列
    toProductMatch() {
      this.$router.push('mProductMatch')
      this.move()
    },
    // 跳转至智能无线保管柜系列
    toProductCabinet() {
      this.$router.push('mProductCabinet')
      this.move()
    },
    // 跳转至视频传输应用解决方案
    toSolutionVideo() {
      this.$router.push('mSolutionVideo')
      this.move()
    },
    // 跳转至数据采集应用解决方案
    toSolutionData() {
      this.$router.push('mSolutionData')
      this.move()
    },
    // 跳转至移动机器无线覆盖传输解决方案
    toSolutionRobot() {
      this.$router.push('mSolutionRobot')
      this.move()
    },
    // 跳转至智能无线保管柜解决方案
    toSolutionCabinet() {
      this.$router.push('mSolutionCabinet')
      this.move()
    },
    // 跳转至服务&维修
    toSupport() {
      this.$router.push('mSupport')
      this.move()
    },
    // 跳转至资源中心
    toAssets() {
      this.$router.push('mAssets')
      this.move()
    },
    // 跳转至关于我们
    toAbout() {
      this.$router.push('mAbout')
      this.move()
    },
    // 导航栏颜色切换
    showSilderBar() {
      this.show = !this.show
      if (this.show == true) {
        this.$refs.topBar.style.backgroundColor = '#c3a35d'
        // document.body.addEventListener('touchmove', function (e) {
        //   e.preventDefault()
        // }, { passive: false })
        this.stop()
      } else {
        this.$refs.topBar.style.backgroundColor = 'transparent'
        // var fun = function (e) { e.preventDefault(); };
        // document.body.style.overflow = '';
        // document.removeEventListener("touchmove", fun, false);
        this.move()
      }
    }
  },
}
</script>

<style scoped>
.topBar {
  z-index: 9999;
  width: 100%;
  height: 255px;
  line-height: 250px;
  color: #ffffff;
  /* box-shadow: 10px 20px 50px 1px rgb(32 32 32 / 30%); */
}

.topBar .fl {
  padding-left: 50px;
  height: 210px;
}

.topBar .fl .logo {
  vertical-align: middle;
  height: 180px;
}

.topBar .fr {
  text-align: center;
  width: 250px;
  height: 250px;
  background-color: #c3a35d;
}

.topBar .fr .sildeBtn {
  vertical-align: middle;
  height: 120px;
}

.silderBar {
  /* display: none; */
  z-index: 999;
  position: absolute;
  top: 250px;
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  height: 100%;
  background-color: #f1f2f6;
}

.silderBar .li {
  color: #c3a35d;
  font-size: 82px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.silderBar .proLi {
  display: none;
}

.silderBar .solLi {
  display: none;
}

.silderBar .supLi {
  display: none;
}

.silderBar .littleLi {
  color: #c3a35d;
  font-size: 70px;
  border-bottom: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, .1); */
}

.silderBar .icon {
  float: right;
}

.silderBar .icon .pre {
  height: 100px;
  line-height: 200px;
  vertical-align: middle;
}

.silderBar .text {
  margin-top: 1800px;
  text-align: center;
  font-size: 70px;
  font-family: kaiti;
  color: #c3a35d;
}
</style>
