<template>
    <div class="baseBar">
        <div class="logo">
            <img src="../assets/mobile/common/pic/logoWW.png" alt="" class="pic">
        </div>
        <div class="msg">
            <div>
                <span>浙江省杭州市西湖区振华路298号西港发展中心西4幢3楼</span>
            </div>
            <div>
                <span>0571-85362275</span>
            </div>
            <div>
                <span>销售咨询: sales@linkom.com.cn</span>
            </div>
            <div>
                <span>技术咨询: support@linkom.com.cn</span>
            </div>
        </div>
        <div class="baseText">
            <span class="txt">专注无线智能产品及方案</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'baseBar',
    components: {

    }
}
</script>

<style scoped>
.baseBar {
    width: 100%;
    color: #ffffff;
    background-color: #c3a35d;
}

.baseBar .logo {
    padding-top: 50px;
    padding-left: 50px;
    margin-bottom: 100px;
}

.baseBar .logo .pic {
    height: 180px;
}

.baseBar .msg {
    margin-bottom: 50px;
    padding-left: 100px;
    color: #ffffff;
    font-size: 70px;
}

.baseBar .baseText {
    width: 100%;
    padding-bottom: 50px;
    text-align: center;
}

.baseBar .baseText {
    font-size: 70px;
    font-family: kaiti;
    color: #796539;
}
</style>
